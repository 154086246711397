<template>
  <div class="login-page">
    <div class="title">
      请输入绑定的手机号码
    </div>
    <div class="light-color size26 mb68">
      手机号码验证成功后可设置新的号码
    </div>
    <van-form @submit="onSubmit">
      <label class="form-label">手机号</label>
      <van-field
        v-model="phoneNum"
        maxlength="11"
        placeholder="请输入手机号"
      />
      <label class="form-label">验证码</label>
      <van-field
        v-model="verifyCode"
        maxlength="6"
        placeholder="请输入验证码"
      >
        <template #button>
          <Countdown
            :phone-num="phoneNum"
            @get-code-success="getCodeSuccess"
          />
        </template>
      </van-field>

      <div class="btn-box">
        <van-button round block class="login-btn" :disabled="disabled">
          下一步
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
 import Countdown from '../../../components/countdown/Countdown'
export default {
  components: {
    Countdown
  },
  data() {
    return {
      phoneNum: '',
      verifyCode: '',
      hasGetCode: false
    }
  },
  computed: {
    disabled() {
      return !(this.phoneNum && this.verifyCode)
    }
  },
  methods: {
    getCodeSuccess() {
      this.hasGetCode = true
    },
    onSubmit() {
      if (!this.hasGetCode) {
        return this.$toast('请先获取验证码！')
      }
      this.$router.push('/setPassword?phone='+this.phoneNum+"&code="+this.verifyCode)
    }
  }
}
</script>
<style scoped lang="less">
@import "../../../assets/styles/variable.less";
.form-label{
  display: block;
  margin-top: 40px;
  color: @dark-font-color;
}
.van-cell{
  padding-left: 0;
  padding-right: 0;
  &::after{
    border-bottom: 0;
  }
}
.mb68{
  margin-bottom: 68px;
}
.van-field{
  border-bottom: 1px solid #ddd;
}
.login-btn{
  width: 686px;
  height: 80px;
  font-size: 30px;
  color: #ffffff;
  background: #3A8AE5;
}
.login-page{
  padding: 0 32px;
  .logo{
    margin-top:90px;
    width: 556px;
    height: 67px;
  }
  .title{
    margin-top:60px;
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: bold;
    color: @dark-font-color;
  }
  .form{
    margin-top: 120px;

    .form-item{
      margin-top: 50px;
      .label{
        font-size: 30px;
        font-weight: bold;
        color: @dark-font-color;
      }
      .input{
        position: relative;
      }
    }

  }
  .btn-box{
    margin: 70px 0 24px;
    .disable{
      background: #B1D3FA;
    }
  }
}
</style>
